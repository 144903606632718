<template>
    <div class="container my-4">
        <div class="card">
            <div class="card-header">Quick Ship</div>
            <div class="card-body d-flex flex-column p-0" :class="{'justify-content-center': loading}">
                <Spinner v-if="loading" size="60" line-fg-color="gray"/>
                <div v-if="!loading">
                    <div class="btn-group d-flex hidden-xs" role="group">
                        <button v-for="(value, key, index) in steps"
                                @click="changeStep(key)"
                                :disabled="key === 'shipping' && !steps['address'].complete"
                                data-toggle="tooltip"
                                data-placement="top"
                                :title="key === 'shipping' && !steps['address'].complete ? 'Complete address information before editing shipment settings' : ''"
                                type="button"
                                class="btn btn-default"
                                :class="{'activeButtonStep': currentStep === key}"
                                style="border-radius: 0; border-color: #5a6169;"
                                :style="{'color': currentStep === key ? 'white' : 'initial'}">
                            {{ index + 1 }} - {{ value.name }} {{ key === 'items' ? '(Optional)' : '' }}
                        </button>
                    </div>
                    <div v-show="currentStep === 'address'">
                        <ShippingAddressForm :shippingAddress="shippingAddress"
                                             :gettingRates="gettingRates"
                                             @update-address="address = $event"
                                             @shipment-data-changed="resetData"
                                             class="p-4"/>
                    </div>
                    <div v-show="currentStep === 'items'">
                        <div class="px-4">
                            <input ref="scannerInput"
                                   v-model="barcode"
                                   @change="onItemScanned"
                                   @focus="isScannerFocused = true"
                                   @blur="isScannerFocused = false"
                                   type="text"
                                   class="ui-hidden-accessible form-control"/>
                            <div class="row">
                                <div class="col" style="cursor: pointer;" @click="focusScanner">
                                    <div class="scan-module d-flex align-items-center justify-content-center">
                                        <div v-if="error" class="d-flex flex-column align-items-center justify-content-center">
                                            <i class="fal fa-times"></i>
                                            {{ error }}, click to refocus
                                        </div>
                                        <div v-else-if="isScannerFocused">
                                            <Spinner size="75" line-fg-color="gray" style="margin-bottom: 10px"/>
                                            <span>Waiting for UUID scan</span>
                                        </div>
                                        <div v-else class="d-flex flex-column align-items-center justify-content-center">
                                            <i class="fal fa-eye-slash"></i>
                                            <span class="mt-2">Focus lost, click to refocus</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col d-flex">
                                    <ul class="list-group flex-fill">
                                        <ItemList :items="items"/>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-show="currentStep === 'shipping'">
                        <ShipmentCarriers v-if="this.numCarriers > 1"
                                          @num-carriers="numCarriers = $event"
                                          @get-carrier="changedCarrier($event)"
                                          @updateSelectedShipmentConfirmationTypeId="selectedShipmentConfirmationTypeId = $event"
                                          class="p-4"/>
                        <div v-if="carrier.provider === 'SE'">
                            <ShipmentPackages :shippingAddress="shippingAddress"
                                              :shippingAddressIsValid="steps['address'].complete"
                                              :gettingRates="gettingRates"
                                              :carrier="carrier"
                                              :selected-shipment-confirmation-type-id="selectedShipmentConfirmationTypeId"
                                              @get-rates-data="setRates"
                                              @getting-rates="gettingRates = $event"
                                              @shipping-data-changed="resetData"
                                              class="p-4"/>
                            <ShipmentRates :rates="rates"
                                           :shipEngineId="shipEngineId"
                                           :selectedRateId="selectedRateId"
                                           @update-rate-id="selectedRateId = $event"
                                           class="p-4"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer d-flex">
                <button @click="previousStep"
                        v-if="currentStep !== 'address'"
                        class="btn btn-sm btn-secondary">
                    Previous
                </button>
                <button @click="nextStep"
                        v-if="currentStep !== 'shipping'"
                        class="btn btn-sm btn-secondary ml-auto">
                    Next
                </button>
                <button @click="createQuickShipment"
                        v-if="currentStep === 'shipping'"
                        :disabled="gettingRates || !readyToCreateShipment"
                        class="btn btn-sm btn-secondary ml-auto">
                    Create shipment
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import ShippingAddressForm from '../../components/forms/ShippingAddressForm.vue';
import ShipmentCarriers from '../../components/shipments/ShipmentCarriers.vue';
import ShipmentPackages from '../../components/shipments/ShipmentPackages.vue';
import ShipmentRates from '../../components/shipments/ShipmentRates.vue';
import {createShipment, getCarriers} from "../../services/ShipmentService";
import Spinner from 'vue-simple-spinner';
import {getItem} from "../../services/ItemService";
import ItemList from '../../components/orders/ItemList.vue';

export default {
    name: 'QuickShip',
    components: {
        ShippingAddressForm,
        ShipmentCarriers,
        ShipmentPackages,
        ShipmentRates,
        Spinner,
        ItemList
    },
    data() {
        return {
            rates: [],
            shipEngineId: null,
            selectedRateId: null,
            gettingRates: false,
            loading: false,
            carrier: {},
            numCarriers: 2,
            shippingAddress: {
                name: '',
                company_name: '',
                address_line1: '',
                address_line2: '',
                city_locality: '',
                state_province: '',
                postal_code: '',
                country_code: ''
            },
            items: [],
            barcode: '',
            error: '',
            isScannerFocused: false,
            currentStep: 'address',
            selectedShipmentConfirmationTypeId: null
        };
    },
    computed: {
        steps() {
            return {
                'address': {
                    name: 'Address',
                    available: true,
                    complete: !!(this.shippingAddress.name &&
                        this.shippingAddress.address_line1 &&
                        this.shippingAddress.city_locality &&
                        this.shippingAddress.state_province &&
                        this.shippingAddress.postal_code &&
                        this.shippingAddress.country_code)
                },
                'items': {
                    name: 'Items',
                    available: true,
                    complete: true
                },
                'shipping': {
                    name: 'Shipping',
                    available: true,
                    complete: true
                }
            }
        },
        readyToCreateShipment() {
            return this.steps['address'].complete && this.carrier.id && (
                this.carrier.provider === 'SE' ? this.shipEngineId && this.selectedRateId : true
            )
        },
    },
    methods: {
        setRates(data) {
            this.rates = data.rates;
            this.shipEngineId = data.shipment_id;
        },
        changedCarrier(carrier) {
            this.carrier = carrier;
            if (carrier.provider !== 'SE') {
                this.shipEngineId = null;
                this.selectedRateId = null;
                this.rates = [];
            }
        },
        async createQuickShipment() {
            try {
                this.loading = true;
                const response = await createShipment(
                    this.carrier.provider === 'SE' ? this.shipEngineId : null,
                    this.carrier.provider === 'SE' ? this.selectedRateId : null,
                    this.carrier.id,
                    null,
                    null,
                    this.items
                );
                this.$router.push({ name: 'shipment-details', params: { id: response.data.id } })
            } catch (error) {
                console.log(error);
            }
            this.loading = false;
        },
        resetData() {
            this.rates = [];
            this.selectedRateId = null;
        },
        focusScanner() {
            this.error = '';
            this.barcode = '';
            if (this.$refs.scannerInput) this.$refs.scannerInput.focus();
        },
        async onItemScanned() {
            try {
                const uuid = this.barcode.toLowerCase();
                this.barcode = '';

                const response = await getItem(uuid);
                const item = response.data;

                if (!item.in_stock) return (this.error = 'Item is out of stock');

                this.items.push(item);
                this.error = '';
            } catch (error) {
                this.error = 'Item not found in inventory';
            }
        },
        changeStep(stepName) {
            if (this.currentStep !== stepName) {
                this.currentStep = stepName;
            }
        },
        nextStep() {
            if (this.currentStep === 'address') {
                this.currentStep = 'items';
            } else if (this.currentStep === 'items') {
                this.currentStep = 'shipping'
            }
        },
        previousStep() {
            if (this.currentStep === 'shipping') {
                this.currentStep = 'items';
            } else if (this.currentStep === 'items') {
                this.currentStep = 'address'
            }
        }
    }
};
</script>

<style scoped>
    .card-body {
        min-height: 300px;
    }

    .ui-hidden-accessible {
        position: absolute !important;
        height: 1px;
        width: 1px;
        overflow: hidden;
        clip: rect(1px, 1px, 1px, 1px);
    }

    .scan-module {
        min-height: 300px
    }

    .fa-times {
        color: red;
        font-size: 75px;
    }

    .fa-check {
        color: green;
        font-size: 75px;
    }

    .fa-eye-slash {
        font-size: 75px;
    }

    .col >>> .list-group-item {
        border-radius: 0;
        border-right: 0;
    }

    .col >>> .list-group-item:first-child {
        border-top: 0;
    }

    .col >>> .list-group-item:last-child {
        border-bottom: 0;
    }

    .activeButtonStep {
        background-color: #5a6169;
        border-color: #5a6169;
    }
</style>
