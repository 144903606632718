<template>
    <div>
        <div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Name</label>
                        <input
                            type="text"
                            class="form-control form-control-sm"
                            v-model="shippingAddress.name"
                            required
                        />
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="d-flex align-items-center">
                            Company name
                            <small class="ml-2">(Optional)</small>
                        </label>
                        <input
                            type="text"
                            class="form-control form-control-sm"
                            v-model="shippingAddress.company_name"
                        />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-6">
                    <label>Street address</label>
                    <input
                        ref="autocomplete"
                        @keypress.enter.prevent
                        type="text"
                        class="form-control form-control-sm"
                        v-model="shippingAddress.address_line1"
                        required
                    />
                </div>
                <div class="form-group col-md-6">
                    <label class="d-flex align-items-center">
                        Street address 2
                        <small class="ml-2">(Optional)</small>
                    </label>
                    <input
                        type="text"
                        class="form-control form-control-sm"
                        v-model="shippingAddress.address_line2"
                    />
                </div>
            </div>

            <div class="row">
                <div class="col-md-3">
                    <div class="form-group">
                        <label>City</label>
                        <input
                            type="text"
                            class="form-control form-control-sm"
                            v-model="shippingAddress.city_locality"
                            required
                        />
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label>State</label>
                        <input
                            type="text"
                            class="form-control form-control-sm"
                            minlength="2"
                            maxlength="2"
                            v-model="shippingAddress.state_province"
                            required
                        />
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label>Zip code</label>
                        <input
                            type="text"
                            class="form-control form-control-sm"
                            v-model="shippingAddress.postal_code"
                            required
                        />
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label>Country</label>
                        <input
                            type="text"
                            class="form-control form-control-sm"
                            minlength="2"
                            maxlength="2"
                            v-model="shippingAddress.country_code"
                            required
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ShippingAddressForm',
    props: {
        gettingRates: Boolean,
        shippingAddress: Object
    },
    data() {
        return {
            autocomplete: null
        };
    },
    mounted() {
        this.initAutocomplete();
    },
    watch: {
        shippingAddress: {
            handler: function () {
                this.$emit('shipment-data-changed')
            },
            deep: true
        }
    },
    methods: {
        initAutocomplete() {
            this.autocomplete = new google.maps.places.Autocomplete(this.$refs.autocomplete, {
                types: ['geocode']
            });
            this.autocomplete.setComponentRestrictions({ country: ['us'] });
            this.autocomplete.addListener('place_changed', this.fillInAddress);
        },
        fillInAddress() {
            this.autocomplete.getPlace().address_components.forEach(comp => {
                const type = comp.types[0];
                if (type === 'street_number') {
                    this.shippingAddress.address_line1 = comp.short_name;
                } else if (type === 'route' && !!this.shippingAddress.address_line1) {
                    this.shippingAddress.address_line1 += ` ${comp.long_name}`;
                } else if (type === 'locality') {
                    this.shippingAddress.city_locality = comp.long_name;
                } else if (type === 'administrative_area_level_1') {
                    this.shippingAddress.state_province = comp.short_name;
                } else if (type === 'country') {
                    this.shippingAddress.country_code = comp.short_name;
                } else if (type === 'postal_code') {
                    this.shippingAddress.postal_code = comp.short_name;
                }
            });
        }
    }
};
</script>